html,
body {
  min-height: 100%;
}

body {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal {
  width: 400px;
  height: 200px;
  line-height: 200px;
  position: fixed;
  top: 54%;
  left: 46%;
  margin-top: -100px;
  margin-left: -150px;
  background-color: #ff2150;
  color: white;
  border-radius: 5px;
  text-align: center;
  z-index: 11; /* 1px higher than the overlay layer */
}

.wrapper {
  position: relative;
  height: 100%;
}

.sidebar {
  position: fixed;
  height: 100%;
  z-index: -10;
  width: 24%;
  top: 0;
  left: 0;
}

.sidebar img {
  position: fixed;
  animation: rotation 60s infinite linear;
  top: -7em;
  left: -7em;
  max-width: 50%;
  max-height: 50%;
}

/* rotation for sidebar img */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.sidebar img:hover {
  cursor: pointer;
}

/* marquee */
.header {
  position: absolute;
  height: 5em;
  width: 44%;
  left: 48%;
  margin-top: 2em;
  text-align: right;
  border-bottom: 4px solid lightgrey;
}

.toggle {
  margin-left: auto;
  margin-right: 0;
  font-size: 20px;
  height: 22px;
  width: 22px;
}

.toggle:hover {
  cursor: pointer;
}

.navbar {
  width: 9em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2em;
  margin-bottom: 1.5em;
}

.navbar a {
  color: #ff2150;
  font-weight: 700;
  text-decoration: none;
}
.navbar a:hover {
  cursor: pointer;
  color: lightgrey !important;
  cursor: pointer;
}

.content {
  position: absolute;
  margin-top: 14em;
  margin-left: 24%;
  padding-bottom: 4em;
  width: 68%;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.banner {
  height: 100%;
  width: 4em;
  margin: auto;
  background-color: aquamarine;
  align-content: space-between;
}

/* blinking cursor after rotating text */
.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
}
@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.footer {
  position: absolute;
  right: 0;
  height: 6em;
  width: 44%;
  text-align: right;
  border-top: 4px solid lightgrey;
}

.footer a {
  color: lightgray;
  font-weight: 500;
  text-decoration: none;
}

.footer a:hover {
  cursor: pointer;
  color: #ff2150 !important;
  cursor: pointer;
}

@media only screen and (min-width: 1280px) {
  .content {
    margin-top: 14em;
  }
  .sidebar img {
    top: -5em;
    left: -5em;
    max-width: 50%;
    max-height: 50%;
  }
}

/* iPad */
@media only screen and (max-width: 667px) {
  .content {
    margin-top: 11em;
  }
  .sidebar img {
    top: -4em;
    left: -4em;
    max-width: 50%;
    max-height: 50%;
  }
}

/* iPhone 7 */
@media only screen and (max-width: 375px) {
  .content {
    margin-top: 12em;
  }

  .sidebar img {
    top: -3em;
    left: -3em;
    max-width: 50%;
    max-height: 50%;
  }
}
